<template>
  <v-card>
    <v-card-title>
      <v-btn
        @click="$router.push({ name: 'KBGenes' })"
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="
          $router.push({
            name: 'EPGGeneDetail',
            params: { geneId }
          })
        "
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-book
        </v-icon>
      </v-btn>
      <v-btn class="mx-2" fab dark small color="green">
        <v-icon dark>
          mdi-content-save
        </v-icon>
      </v-btn>
      <v-btn class="mx-2" fab dark small color="red">
        <v-icon dark>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12 px-5" sm="12" md="6">
              <v-card>
                <v-text-field
                  v-model="kbData.symbol"
                  :rules="validationRules.slugRules"
                  label="Símbolo"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="kbData.omim"
                  :rules="validationRules.slugRules"
                  label="OMIM"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="kbData.locus"
                  :rules="validationRules.slugRules"
                  label="Locus"
                  required
                ></v-text-field>
              </v-card>
              <v-card>
                <v-card-title>
                  Nombres principales
                </v-card-title>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.en"
                      label="Inglés"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.es"
                      label="Español"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.po"
                      label="Portugués"
                      required
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card>
              <v-card>
                <v-switch v-model="kbData.has_epg" label="Tiene EPG"></v-switch>
                <v-switch
                  v-model="kbData.has_card"
                  label="Tiene Tarjeta"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <MultipleInput
                label="Nombres alternativos"
                v-model="kbData.alternative_names"
                textField="name"
              />

              <MultipleInput
                label="Símbolos alternativos"
                v-model="kbData.alternative_symbols"
                textField="symbol"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import MultipleInput from "@/components/mylogy/MultipleInput.vue";
export default {
  props: ["geneId"],
  components: { MultipleInput },
  data() {
    return {
      valid: false,
      validationRules: {
        slugRules: [v => !!v || "El campo ID es obligatorio"]
      },
      kbData: {
        alternative_names: [],
        alternative_symbols: [],
        symbol: null,
        omim: null,
        locus: null,
        has_epg: null,
        has_card: false,
        id: null,
        induces: [],
        inhibits: [],
        kb_inductions: [],
        kb_inhibitios: [],
        kb_relationships: [],
        kb_subs: [],
        main_names: {
          en: null,
          es: null,
          po: null
        }
      }
    };
  },
  computed: {},
  mounted() {
    let self = this;
    Strapi.get(`/kb-genes/${this.geneId}`).then(res => {
      self.kbData = res.data;
      console.log(res.data);
    });
  },
  methods: {}
};
</script>
<style scoped></style>
